<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="width-480 ma-auto bg-white flex-column pa-40-30 radius-20 position-relative"
				style="z-index: 998; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
			>
				<div class="full-height overflow-y-auto text-center">
					<div class="bg-logo text-center">
						<h4 class="font-weight-bold">{{ cartel_info.cartl_name }}</h4>
							<div class="popup-close">
								<img class="cursor-pointer" @click="$emit('cancel')" :src="require('@/assets/image/web/notify_cartel/ico_close_bk.svg')" />
							</div>
					</div>
					<div class="top-line mt-30 pt-30">
						<div
						class="radius-12 pa-20"
						style="background: rgba(23, 28, 97, 0.03);"
					>
						<h5 class="color-mafia-blue font-weight-500 line-height-140">{{ result_info.subscrp_plan_name }}</h5>
						<div class="mt-10 size-px-14 color-333 line-height-140">{{ $language.subscribe.txt_subscribe_complete }} <!-- 구독이 완료되었습니다. --></div>
						</div>
					</div>

				</div>
				<div
					class="mt-auto "
				>
					<div class="mt-20 justify-space-between-in size-px-14">
						<div class="">
							<div class="color-333">{{ $language.subscribe.txt_subscribe_complete }} <!-- 구독 플랜 --></div>
							<div class="font-weight-500">{{  result_info.subscrp_plan_name }}</div>
						</div>
						<div class="mt-10">
							<div class="color-333">{{ $language.point_result.title_payment_type }} <!-- 결제 수단 --></div>
							<div class="font-weight-500">{{  result_info.payment_mthd_name }}</div>
						</div>
						<div class="mt-10">
							<div class="color-333">{{ $language.subscribe.txt_subscribe_complete }} <!-- 결제 금액 --></div>
							<div class="font-weight-700 color-red-dc size-px-16">{{  result_info.tot_vat_price ? result_info.tot_vat_price : result_info.coin_token_quantity ? result_info.coin_token_quantity : result_info.point ? result_info.point : '' | makeComma }} <template v-if="result_info.tot_vat_price">원</template></div>
						</div>
						<div class="mt-10">
							<div class="color-333">{{ $language.subscribe.txt_subscribe_period }} <!-- 구독 기간 --></div>
							<div class="font-weight-500">{{  result_info.subscrp_startdt }} ~ {{ result_info.subscrp_enddate}}</div>
						</div>
					</div>
					<div
						class="mt-30 flex-row justify-center"
					>
						<button
							class="btn-primary pa-13-30 color-white size-px-14 font-weight-500 radius-20 line-height-1"
							style="outline: none;"

							@click="toCartel"
						>{{ $language.point_result.btn_confirm }} <!-- 확인 --></button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'CartelSubscribeResult'
	, components: {PopupLayer},
	props: ['user', 'result_info', 'cartel_info']
	, methods: { 
		toCartel: function(){
			this.$bus.$emit('to', { name: 'mafia049', params: { idx: this.$route.params.idx }})
		}
	}
}
</script>