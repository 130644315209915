<template>
	<CartelSubscribeResult
		v-if="is_on_result"
		:user="user"
		:result_info="item_result"
		:cartel_info="item_cartel"

	></CartelSubscribeResult>
	<PopupLayer
		v-else
		class="full-height"
	>
		<template
			v-slot:body
		>
			<div
				class="flex-column radius-20 text-left  radius-20 overflow-hidden" 
				style="min-width: 320px; max-width: 480px; margin: 0 auto; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
			>
				<div class="bg-mafia-blue flex-row justify-space-between items-center pa-13-30 pr-20">
					<h4 class="color-white">카르텔 구독</h4>

					<img class="cursor-pointer"
                    @click="$emit('cancel')"
                    :src="require('@/assets/image/web/notify_cartel/ico_close.svg')"
                />
				</div>
				<div class="pa-30 full-height overflow-y-auto bg-white scroll-black"
					style="max-height: 410px;"
				>
					<div>
						<div
							class="text-center size-px-16"
						>
							<div class="img-box-100 radius-12 mt-10"><img :src="item_plan.subscrp_plan_img_url" @error="$bus.$emit('onErrorImage', $event)" class="object-cover" /></div>
							<div class="mt-10 font-weight-500 color-333">{{ item_plan.subscrp_plan_name }}</div>
							<div class="mt-10 color-mafia-blue flex-row gap-5 justify-center">
								<div class="flex-row justify-center align-center gap-5">
									<img :src="require('@/assets/image/icon_won_blue.svg')" />
									<span class="font-weight-500 color-blue-3e line-height-1">{{ item_plan.subscrp_plan_price | makeComma }}원</span>
								</div>
								<span class="size-px-14">/ 월 (VAT 별도)</span></div>
						</div>
						<div class="radius-12 mt-30 pa-20 text-left"
							style="background: rgba(23, 28, 97, 0.03);"
						>
							<h3 class="flex-row items-center gap-10"><img :src="require('@/assets/image/web/setting_cartel/icon_subscribe_desc.svg')"> {{ $language.cartel_new.title_subscribe_plan_info }} <!-- 구독 플랜 설명 --></h3>
							<div
								:inner-html.prop="item_plan.subscrp_plan_desctn | nl2br"
								class="size-px-14 mt-15 line-height-160 top-line pt-15"
							></div>
						</div>

						<div
							class="mt-30 position-relative"
						>
							<select
								v-model="payment_number"
								class="input-box size-px-14 pa-10-20 radius-20 cursor-pointer "
								@change="setPayment"
							>
								<option class="size-px-14" value="">{{ $language.cartel_new.select_payment }} <!-- 결제수단을 선택하세요 --></option>
								<option
									class="size-px-14"
									v-for="(payment, p_index) in payment_list"
									:key="'payment_' + p_index"
									:value="payment.subscrp_plan_payment_mthd_number"
								>{{ payment.payment_mthd_name}}</option>
							</select>
							<v-icon
								class="position-absolute" style="right: 10px; top: 8px"
							>mdi-chevron-down</v-icon>
						</div>
					</div>

					<div
						v-if="item_payment.payment_type_code == 'PM00300003'"
						class="justify-space-between pa-20 mt-20 radius-12"
						style="background: rgba(23, 28, 97, 0.03);"
					>
						<div class="font-weight-500 size-px-14 color-333">{{ $language.point.title_my_point }} <!-- 보유 중인 불렛 --> </div>
						<div class="font-weight-bold size-px-16 color-red-dc">{{ item_point.holding_point | makeComma }}</div>
					</div>
					<div
						v-else-if="item_payment.payment_type_code == 'PM00300002'"
						class="justify-space-between pa-20 mt-20 radius-12"
						style="background: rgba(23, 28, 97, 0.03);"
					>
						<div class="font-weight-500 size-px-14 color-333">{{ $language.point.title_my_quantity }} <!-- 보유 수량 --> </div>
						<div class="font-weight-bold size-px-16 color-red-dc">{{ item_wallet.holding_coin_quantity | toFixed(8) }}</div>
					</div>

					<div
						class="justify-space-between-in pt-20 size-px-14"
					>
						<div
							v-if="is_upgrade"
							class=""
						>
							<div class="color-333">{{ $language.cartel_new.title_plane_now }} <!-- 현재 구독 플랜 --> <br/> <br/></div>
							<div class="font-weight-500 text-right">
								{{  item_plan.current_subscrp_plan_name }}
								<br/>
								{{ item_plan.current_subscrp_enddate }} {{ $language.cartel_new.etc_end }} <!-- 까지 -->
							</div>
						</div>
						<div
							v-else
							class=""
						>
							<div class="color-333">{{ $language.cartel_new.title_plane_now }} <!-- 현재 구독 플랜 --> </div>
							<div class="font-weight-500">{{ $language.cartel_new.title_not_subscribe_plan }} <!-- 구독 플랜 없음 --></div>
						</div>
						<div class="mt-10">
							<div class="color-333">{{ $language.cartel_new.title_plane_amount }} <!-- 구독 플랜 금액 --></div>
							<div class="font-weight-500">{{  item_plan.subscrp_plan_price | makeComma }}원</div>
						</div>
						<div
							v-if="is_upgrade"
							class="mt-10"
						>
							<div class="color-333">{{ $language.cartel_new.title_plane_amount_upgrade }} <!-- 업그레이드 금액 --> </div>
							<div class="font-weight-500">{{  item_plan.upgrade_subscrp_plan_price | makeComma }}원</div>
						</div>
						<div class="mt-10">
							<div class="color-333">{{ $language.cartel_new.title_plane_vat }} <!-- 부가세 --> </div>
							<div class="font-weight-500">{{  tax | makeComma }}원</div>
						</div>
						<div class="mt-10">
							<div class="color-333">{{ $language.cartel_new.title_plane_total_price }} <!-- 총 결제 금액 --> </div>
							<div class="font-weight-700 color-red-dc size-px-16">{{  total_price | makeComma }}원</div>
						</div>

						<div
							v-if="item_payment.subscrp_plan_payment_mthd_number && item_payment.payment_type_code != 'PM00300001'"
							class="mt-10"
						>
							<div class="color-333">{{ $language.staking.txt_staking_history_quantity }} <!-- 수량 --> </div>
							<div class="font-weight-700 color-red-dc size-px-16">{{  total_price_count  }}</div>
						</div>
					</div>

					<div
						v-if="item_payment.payment_type_code == 'PM00300003'"
						class="pa-20 flex-row justify-center"
					>
						<div class=""
						><button
							class="btn-inline btn-primary radius-20 pa-13-30 font-weight-500"
							style="outline: none;"
							@click="toPoint"
						>{{ $language.point.txt_heart }} <!-- 불렛 --> {{ $language.point.btn_charge }} <!-- 충전 --></button></div>
					</div>

					<div
						class="top-line pt-30 mt-30 text-left"
					>
						<h5>{{ $language.cartel_new.title_notice }} <!-- 유의사항 --></h5>
						<div class="mt-10 color-333 size-px-13 line-height-140 subscribe_submenu">
							<div>
								<span class="inline-block">{{ $language.cartel_new.txt_notice1 }}</span> <!-- 구독 업그레이드 상품의 경우 남은 구독 기간은 자동 취소되며 환불금을 제외한 차액을 결제합니다. -->
							</div>
							<div class="mt-5">
								<span class="inline-block">{{ $language.cartel_new.txt_notice2 }}</span> <!-- 본 상품은 “서비스 이용약관"에 따른 구독 플랜 결제로 동의약관이 적용됩니다.-->
							</div>
							<div class="mt-5">
								<span class="inline-block">{{ $language.cartel_new.txt_notice3 }}</span> <!-- 구독 상품 특성 상 구입 철회가 불가능한 상품입니다.-->
							</div>
						</div>
						<div class="mtb-30">
							<label
								@click="is_agree = !is_agree"
							>
								<v-icon
									v-if="is_agree"
									class="color-mafia-blue"

								>mdi-checkbox-marked-circle</v-icon>
								<v-icon
									v-else
								>mdi-checkbox-blank-circle-outline</v-icon>
								<span class="size-px-14 ml-5">{{ $language.cartel_new.txt_notice4 }}</span> <!-- 상기 구독 플랜 상품 유의사항에 동의합니다.(필수) -->
							</label>
						</div>
					</div>

					<div class="mt-30 flex-row justify-center">
					<button
						@click="onPayment"
						:disabled="is_disabled"

						class="bg-primary pa-13-30 color-white size-px-14 font-weight-500 radius-20 line-height-1"
					>{{ $language.cartel_new.btn_subscribe}} <!-- 구독 --></button>
					</div>
				</div>
			</div>

			<MyCredit
				v-if="is_on_credit"
				:user="user"
				@cancel="is_on_credit = false"
				@click="setCredit"
			></MyCredit>
		</template>

		<Popup_select
			v-if="is_on_payment"
			@cancel="is_on_payment = false"
		>
			<template
				v-slot:title
			>{{ $language.point_result.title_payment_type }}</template>
			<template
				v-slot:list
			>
				<li
					v-for="(payment, p_index) in payment_list"
					:key="'payment_' + p_index"
					class="ptb-10 mb-10"
					@click="setPayment(payment)"
				>{{ payment.payment_mthd_name }}</li>
			</template>
		</Popup_select>
	</PopupLayer>
</template>

<script>
import MyCredit from "@/view/Mypage/MyCredit";
import CartelSubscribeResult from "@/view/SubscribeCartel/CartelSubscribeResult";
import Popup_select from "@/view/Layout/PopupSelect";
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'CartelSubscribeRequest'
	, components: {PopupLayer, Popup_select, CartelSubscribeResult, MyCredit}
	, props: ['user', 'cartel_number', 'plan_info', 'plan_number', 'plan_payment_type']
	, data: function(){
		return {
			program: {
				name: this.$language.cartel_new.title_cartel_subscribe // '카르텔 구독'
				, title: this.$language.cartel_new.title_cartel_subscribe // '카르텔 구독'
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'
			}
			, item_cartel: {

			}
			, item_plan: {

			}
			, items_payment: [

			]
			, is_agree: false
			, payment: ''
			, is_on_credit: false
			, item_credit: {

			}
			, is_on_result: false
			, item_result: {

			}
			, item_inapp: {

			}
			, item_payment: {}
			, is_on_payment: false
			, item_point: {
				holding_point: 0
			}
			, items_wallet: []
			, item_wallet: {}
			, payment_number: ''
		}
	}
	, computed: {
		is_disabled: function(){
			let t = true
			if(this.is_agree && this.item_payment.subscrp_plan_payment_mthd_number){
				if(this.item_payment.payment_type_code == 'PM00300002'){
					if(Number(this.item_wallet.holding_coin_quantity) > Number(this.total_price_count)){
						t = false
					}
				}else if(this.item_payment.payment_type_code == 'PM00300003'){
					if(Number(this.item_point.holding_point) > Number(this.total_price_count)){
						t = false
					}
				}else{
					t = false
				}
			}
			return t
		}
		, payment_list: function(){
			let d = []
			this.items_payment.filter( (item) => {
				if(item.payment_type_code == 'PM00300001'){
					item.payment_mthd_name = '신용카드'
				}else if(item.payment_type_code == 'PM00300003') {
					item.payment_mthd_name = '불렛'
				}else{
					for(let [key, coin] of Object.entries(this.$codes.tokens)) {
						console.log(key, coin)
						if(coin.coin_token_code == item.coin_token_code){
							item.payment_mthd_name = coin.coin_token_name
							break
						}
					}
				}
				if(item.use_fg == 'Y'){
					d.push(item)
				}
			})
			return d
		}
		, tax: function(){
			let t = 0

			if(this.is_upgrade){
				if(this.item_plan.upgrade_subscrp_plan_price > 0){
					t = this.item_plan.upgrade_subscrp_plan_price * 0.1
				}
			}else{
				if(this.item_plan.subscrp_plan_price > 0){
					t = this.item_plan.subscrp_plan_price * 0.1
				}
			}
			return t
		}
		, plan_price: function(){
			let t = 0
			if(this.is_upgrade){
				t = this.item_plan.subscrp_plan_price
			}else{
				t = this.item_plan.subscrp_plan_price
			}
			return t
		}
		, upgrade_price: function(){
			let t = 0

			return t
		}
		, total_price: function(){
			let t = 0
			if(this.is_upgrade){

				if(this.item_plan.upgrade_subscrp_plan_price > 0){
					t = this.item_plan.upgrade_subscrp_plan_price * 1.1
				}
			}else{

				if(this.item_plan.subscrp_plan_price > 0){
					t = this.item_plan.subscrp_plan_price * 1.1
				}
			}
			return Math.floor(t)
		}
		, total_price_count: function(){
			let t = 0

			if(this.item_plan.coin_token_quantity > 0){
				t = this.item_plan.coin_token_quantity
			}

			return t
		}
		, subscrp_plan_payment_mthd_number: function(){
			let t = ''
			this.items_payment.filter((item) => {
				if(item.payment_type_code == this.item_payment.payment_type_code){
					t = item.subscrp_plan_payment_mthd_number
				}
			})
			return t
		}
		, is_upgrade: function(){
			let t = false
			console.log('this.$route.params', this.$route.params)
			if(this.$route.params.type || this.plan_payment_type){
				t = true
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel_number
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getPlane: async function(){
			try{
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.get_cartel_subscribe_payment
				if(this.is_upgrade){
					url = this.$api_url.api_path.get_cartel_subscribe_payment_upgrade
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel_number
						, subscrp_plan_number: this.plan_number
						, upgrade_subscrp_plan_number: this.plan_number
						, current_subscrp_plan_number: this.plan_payment_type
					}
					, type: true
				})

				if(result.success){
					this.item_plan = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getPlaneToken: async function(){
			try{
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.get_cartel_subscribe_payment_coin
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel_number
						, subscrp_plan_number: this.plan_number
						, subscrp_plan_payment_mthd_number: this.item_payment.subscrp_plan_payment_mthd_number
						, subscrp_plan_price: this.is_upgrade ? this.item_plan.upgrade_subscrp_plan_price : this.item_plan.subscrp_plan_price
						, blockchain_div_code: this.item_payment.blockchain_div_code
						, coin_token_code: this.item_payment.coin_token_code
					}
					, type: true
				})

				if(result.success){
					this.$set(this.item_plan, 'coin_token_quantity', result.data.vat_incl_coin_token_quantity)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getPlanePoint: async function(){
			try{
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.get_cartel_subscribe_payment_point
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel_number
						, subscrp_plan_payment_mthd_number: this.item_payment.subscrp_plan_payment_mthd_number
						, subscrp_plan_number: this.plan_number
						, subscrp_plan_price: this.is_upgrade ? this.item_plan.upgrade_subscrp_plan_price : this.item_plan.subscrp_plan_price
					}
					, type: true
				})

				if(result.success){
					this.$set(this.item_plan, 'coin_token_quantity', Number(result.data.vat_incl_point))
					this.$set(this.item_plan, 'point_reg_number', result.data.point_reg_number)

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getPayment: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_subscribe_payment_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel_number
						, subscrp_plan_number: this.plan_number
					}
					, type: true
				})

				if(result.success){
					this.subscrp_plan_commis_rate = result.data.subscrp_plan_commis_rate
					this.items_payment = result.data.payment_mthd_list
					// this.items = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPaymentList: function(){
			this.is_on_payment = true
		}
		, onPayment: function(){
			switch (this.item_payment.payment_type_code){
				case 'PM00300001':
					this.onCredit()
					break;
				case 'PM00300004':
					try{
						window.android.postMessage('inAppIos')
					}catch (e){
						this.$bus.$emit('notify', { type: 'error', message: '인앱결제를 사용할수 없습니다.'})
					}
					break;
				default:
					this.onPin()
					break;
			}
		}
		, onCredit: function(){
			this.is_on_credit = true
		}
		, onPin: function(){
			this.$bus.$off('pinCallback')
			this.$bus.$on('pinCallback', (type, pin)=> {
				console.log(type, pin)
				this.pinnumber = pin
				if(this.is_upgrade){
					this.postCartelSubscribeUpgrade()
				}else{
					this.postCartelSubscribe()
				}
			})
			this.$bus.$emit('onPin', 'check')
		}
		, postCartelSubscribe: async function(){
			try{
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.post_cartel_subscribe_payment_cash
				switch (this.item_payment.payment_type_code){
					case 'PM00300001':
						url = this.$api_url.api_path.post_cartel_subscribe_payment_cash
						break;
					case 'PM00300002':
						url = this.$api_url.api_path.post_cartel_subscribe_payment_token
						break;
					case 'PM00300003':
						url = this.$api_url.api_path.post_cartel_subscribe_payment_point
						break;
					case 'PM00300004':
						url = this.$api_url.api_path.post_cartel_subscribe_payment_inapp
						break;
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel_number
						, pinnumber: this.pinnumber
						, payment_mthd_number: this.item_credit.payment_mthd_number
						, subscrp_plan_payment_mthd_number: this.item_payment.subscrp_plan_payment_mthd_number
						, subscrp_plan_number: this.plan_number
						, subscrp_plan_price: this.plan_price
						, tot_vat_price: this.total_price
						, coin_token_quantity: this.total_price_count
						, blockchain_div_code: this.item_payment.blockchain_div_code
						, coin_token_code: this.item_payment.coin_token_code
						, point_reg_number: this.item_plan.point_reg_number
						, point: Number(this.total_price_count)
					}
					, type: true
				})

				if(result.success){
					this.item_result = result.data
					this.is_on_result = true
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postCartelSubscribeUpgrade: async function(){
			try{
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.post_cartel_subscribe_upgrade_cash
				switch (this.item_payment.payment_type_code){
					case 'PM00300001':
						url = this.$api_url.api_path.post_cartel_subscribe_upgrade_cash
						break;
					case 'PM00300002':
						url = this.$api_url.api_path.post_cartel_subscribe_upgrade_token
						break;
					case 'PM00300003':
						url = this.$api_url.api_path.post_cartel_subscribe_upgrade_point
						break;
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel_number
						, pinnumber: this.pinnumber
						, payment_mthd_number: this.item_credit.payment_mthd_number
						, subscrp_plan_payment_mthd_number: this.subscrp_plan_payment_mthd_number
						, current_subscrp_plan_number: this.plan_payment_type
						, upgrade_subscrp_plan_number: this.plan_number
						, upgrade_subscrp_plan_price: this.item_plan.upgrade_subscrp_plan_price
						, subscrp_plan_price: this.plan_price
						, tot_vat_price: this.total_price
						, coin_token_quantity: this.total_price_count
						, blockchain_div_code: this.item_payment.blockchain_div_code
						, coin_token_code: this.item_payment.coin_token_code
						, point_reg_number: this.item_plan.point_reg_number
						, point: Number(this.total_price_count)
					}
					, type: true
				})

				if(result.success){
					this.item_result = result.data
					this.is_on_result = true
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setCredit: function(item){
			this.item_credit = item
			this.is_on_credit = false
			this.onPin()
		}
		, setInAppIos: function(item){
			this.item_inapp = item
			this.onPin()
		}
		, getPaymentType: function(){
			console.log('getPaymentType', this.item_payment)
			switch (this.item_payment.payment_type_code){
				case 'PM00300001':
					this.getPlane()
					break
				case 'PM00300002':
					this.getPlaneToken()
					this.getMyWallet()
					break
				case 'PM00300003':
					this.getPlanePoint()
					this.getMyPoint()
					break
			}
		}
		, setPayment: function(){
			this.items_payment.filter( (item) => {
				if(item.subscrp_plan_payment_mthd_number == this.payment_number){
					this.item_payment = item
					this.getPaymentType()
				}
			})
		}

		, getMyPoint: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_user_point
					, data: {
						member_number: this.user.member_number
						, mobile_opsys_code: this.$common.getOsCode()
						, payment_type_code: this.$common.getPaymentCode()
					}
					, type: true
				})

				if(result.success){
					this.item_point = result.data
					if(Number(this.item_point.holding_point) < Number(this.total_price_count)){
						throw this.$language.point_result.txt_not_enough_point // 불렛가 부족합니다
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getWallets: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_wallet_list
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.items_wallet = result.data.wallet_list
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getMyWallet: async function(){
			await this.getWallets()
			for(let [k, v] of Object.entries(this.items_wallet)){
				if(v.blockchain_div_code == this.item_payment.blockchain_div_code && v.coin_token_code == this.item_payment.coin_token_code){
					this.item_wallet = v
					this.item_wallet.index = k
					if(Number(this.item_wallet.holding_coin_quantity) < Number(this.total_price_count)){
						this.$bus.$emit('notify', { type: 'error', message: this.$language.nft.wallet_check_require})
					}
					break
				}
			}
		}
		, toPoint: function(){
			this.$bus.$emit('to', { name: 'PointList'})
		}
	}
	, async created() {
		await this.getPlane()
		await this.getPayment()
	}
}
</script>
<style scoped>
.subscribe_submenu{
	>div{
		position: relative;
		>span{
			position: relative;
			padding-left: 11px;
		}

		>span::after{
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 3px;
		height: 3px;
		border-radius: 100px;
		background-color: var(--light-Gray02);
		}
	}
}

</style>